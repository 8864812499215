import { initHeader } from "./header";
import { initCatalog } from "./catalog";
import { initFaqItems } from "./faq";
import { initFeedbackSwiper } from "./feedback";
import 'swiper/swiper.css'
import 'swiper/modules/pagination.css'
import {RequestForm} from "./form";
import { modalInstance } from "./modal";
import {initMaskito} from "./mask";


initHeader();
initCatalog();
initFeedbackSwiper();
initFaqItems();
initMaskito();

new RequestForm('main_form', () => {
  modalInstance.openThanks();
}, false)