// Header
export function initHeader() {
  let navIsActive = false;
  const navActiveClass = 'header-nav_active';
  const animationTime = 300;
  const $burger = document.getElementById('header_burger');
  const $nav = document.getElementById('header_nav');
  const $links = document.getElementsByClassName('header-nav__link');

  Array.from($links).forEach(($el) => {
    $el.addEventListener('click', () => {
      navIsActive = false
      closeMenu()
    })
  })

  function closeMenu() {
    $nav.classList.remove(navActiveClass);
    $burger.querySelector('img:first-child').style.display = 'inline-block';
    $burger.querySelector('img:last-child').style.display = 'none';
  }

  $burger.addEventListener('click', (e) => {
    e.stopPropagation();
    e.preventDefault();

    navIsActive = !navIsActive;
    if (navIsActive) {
      $nav.style.display = 'block';
      $burger.querySelector('img:first-child').style.display = 'none';
      $burger.querySelector('img:last-child').style.display = 'inline-block';
      setTimeout(() => {
        $nav.classList.add(navActiveClass);
      }, animationTime);
    } else {
      closeMenu()
    }
  })
}
