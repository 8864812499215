export class RequestForm {
  constructor(formId, requestCallback, hideModalAfterRequest) {
    this.$form = document.getElementById(formId);
    this.requestCallback = requestCallback;
    this.hideFormAfterRequest = hideModalAfterRequest;

    this.initForm();
  }

  initForm() {
    this.$form.addEventListener('submit', async (e) => {
      e.preventDefault();
      const data = new FormData(e.target);

      if (!data.get('privacy')) {
        this.shakeFields('.advisory__privacy_wrap');
        return;
      }

      this.sendRequest(data);
      this.requestCallback()

      if (this.hideFormAfterRequest) {
        this.hide()
      }
    })
  }

  shakeFields(selector) {
    const $fields = this.$form.querySelectorAll(selector)
    for (const $field of Array.from($fields)) {
      $field.classList.add('shake-it');
      setTimeout(() => {
        $field.classList.remove('shake-it');
      }, 400)
    }
  }

  async sendRequest(data) {
    // todo temp
    localStorage.setItem('already_sent', 'yes');

    fetch('http://www.biba.ru/url', {
      method: 'POST',
      mode: 'no-cors',
      body: data,
    }).then((response) => {
      if (response.ok) {
        localStorage.setItem('already_sent', 'yes');
      }
    }).catch(() => {
      console.error('Ошибка отправки данных')
    })
  }

  hide() {
    this.$form.classList.add('modal__form_hidden');
  }

  show() {
    this.$form.classList.remove('modal__form_hidden');
  }
}