import { questionsData } from "../data/questions.data";
const $faqItemsParent = document.querySelector('.faq__items');

class FaqItem {
  constructor(data, $parent) {
    this.item = document.getElementById('faq_item_template').content.cloneNode(true);
    this.$parent = $parent;
    this.isActive = false;

    this._setQuestion(data.question);
    this._setAnswer(data.answer);
    this._setListeners();
    this.appendToParent($parent);
  }

  _setListeners() {
    const $root = this.item.querySelector('.faq-item');
    const $el = this.item.querySelector('.faq-item__question');

    $el.addEventListener('click', () => {
      if (this.isActive) {
        $root.classList.remove('faq-item_active');
      } else {
        $root.classList.add('faq-item_active');
      }

      this.isActive = !this.isActive;
    });
  }

  _setQuestion(value) {
    const $el = this.item.querySelector('.faq-item__title');
    $el.innerText = value;
  }

  _setAnswer(value) {
    const $el = this.item.querySelector('.faq-item__answer');
    $el.innerText = value;
  }

  appendToParent() {
    this.$parent.append(this.item)
  }
}

export function initFaqItems() {
  for (const questionDataItem of questionsData) {
    new FaqItem(questionDataItem, $faqItemsParent)
  }
}
