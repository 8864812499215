import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";

export function initFeedbackSwiper() {
  new Swiper('.feedback-swiper', {
    slidesPerView: 'auto',
    modules: [Navigation, Pagination],
    pagination: {
      el: '.feedback__pagination',
      clickable: true,
    },
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
      nextEl: '.swiper-arrow_right',
      prevEl: '.swiper-arrow_left',
    },
  });
}
